import React from "react";
import SEO from "../components/seo";

const NotFound = () => {
  return (
    <>
      <SEO title="Halaman Tidak Ditemukan - Rupiah.trade" />
      <div className="min-h-screen w-screen flex items-center justify-center">
        <div className="p-6 text-center">
          <h1 className=" text-7xl font-extrabold text-gray-500">404</h1>
          <p className="text-gray-500">Halaman tidak ditemukan</p>
          <div className="w-16 h-1 rounded bg-gray-500 mt-6 mx-auto"></div>
          <svg
            className="fill-gray-500 h-12 w-12 mx-auto mt-4 hover:fill-gray-700"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M19.1 11.6h-1.5V1h1.5v10.6zM10 13.9h4v-1.5h-4v1.5zM16.6 1h-1.5v10.6h1.5V1zm-5.1 15.5h1.8V15H10v8h4v-1.5h-2.6v-5zM7.4 3.8l-.5.6-.5-.5V1H4.9v3.5l1 1-1 1v5.2h1.5V7.1l.5-.5.5.4v4.5h1.5V6.4l-1-1 1-1V1H7.4v2.8zm5.1 5.1-.5.6-.5-.5V1H10v8.6l2 2 2-2V1h-1.5v7.9zm5.2 3.5v7.9l-.6.6-.5-.5v-8h-1.5V21l2 2 2-2v-8.5h-1.4zm-8.8 0v8L6.4 23H4.9V12.4h4zm-1.5 1.5H6.3v7l1.1-1.1v-5.9z" />
          </svg>
        </div>
      </div>
    </>
  );
};

export default NotFound;
